<template>
  <section id="sect3" class="section--pb" style="background-color: #1B1A1A; overflow: hidden;">
    <div class="section__title"><span>Мастера</span>
    </div>
    <div class="container">
      <div class="team__subtitle team__subtitle--main">
        <p>Здоровье и красота каждого - богатство всех!</p>
        <p>ВСЕМ <span>“САЛЮТ”</span>
        </p>

      </div>
      <div class="team__subtitle" @click="gtag">Вы можете записаться к мастеру, нажав на его карточку</div>
      <el-tabs type="card">
        <el-tab-pane v-for="(item, index) in branch" :label="item.info.name" :key="item.id">
          <div>
            <div class="team">
                <div class="team__item" v-for="(person, index) in item.team" :key="person.name"
                     :style="'background: url(' + person.img + '); background-size: cover; background-position: center;'">
                  <img :src="person.img" style="position: absolute;" alt="">
                  <div class="team__description" v-if="person.desc.length > 0">{{ person.desc }}
                  </div>
                  <div class="team__name">
                    <p>{{ person.name }}</p>
                  </div>
                  <a class="team__link"
                     :href="person.link"></a>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </section>
</template>
<script>
export default {
  name: "Team",
  computed: {
    branch() {
      return this.$store.state.branch
    }
  },
  methods: {
    gtag() {
      console.log(gtag)
      gtag('event', 'master_click_card', {
        'click_card': 'Currency'
      });
    }
  }
}
</script>

<style lang="scss">

.tabs-component-tabs {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.tabs-component-tab:not(:last-child) {
  margin-right: 10px;
}

.tabs-component-tab-a {
  padding: 15px 62px;
  border: none;
  color: #f5f4f4;
  background: transparent;
  cursor: pointer;
  border-top-left-radius: 4px;
  border-top-right-radius: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  display: block;
  -webkit-transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

@media (max-width: 767px) {
  .tabs-component-tab-a {
    padding: 10px 20px;
  }
  .tabs-component-tabs {
    padding-bottom: 18px;
    display: flex;
    flex-wrap: wrap;
  }
}

.tabs-component-tab-a:hover {
  background-color: #E7634D;
}

.tabs-component-tab-a.is-active {
  background-color: #E7634D;
  color: #f5f4f4;
  -webkit-transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  pointer-events: none;
}


</style>
