<template>
  <section>
    <div class="section__title section__title--desc"><span>
            <p> Делай скрин результатов игры в змейку ниже, отмечай нас в историях, получай <span>скидку</span>
                        </p>
                        </span>
    </div>
    <div class="game-sect">
      <div id="title">нажмите для старта</div>
      <div class="game-sect__bg">
        <canvas id="snakeCanvas" width="800" height="400">
        </canvas>
      </div>
      <div id="tutorial">
        <div class="keybtnGroup">
          <div>
            <div class="keybtn" id="keybtnup">W</div>
          </div>
          <div>
            <div class="keybtn" id="keybtnup">A</div>
            <div class="keybtn" id="keybtnup">S</div>
            <div class="keybtn" id="keybtnup">D</div>
          </div>
        </div>
      </div>
      <div class="game-sect__btn-shell">
        <div>
          <div class="game-sect__btn-top game-sect__btn"></div>
        </div>

        <div>
          <div class="game-sect__btn-left game-sect__btn"></div>
          <div class="game-sect__btn-down game-sect__btn"></div>
          <div class="game-sect__btn-right game-sect__btn"></div>
        </div>

      </div>
    </div>
  </section>

</template>

<script>
export default {
name: "Game",
  mounted() {
      canvas = document.getElementById("snakeCanvas");
      ctx = canvas.getContext("2d");
      reset();
  }
}
</script>

<style scoped>

</style>
