<template>
  <section id="sect1">
    <div class="hero">
      <div class="hero__img" style=" background-position: center 0px;"></div>
      <div class="hero__content" style="position: relative; top: 0px;">
        <div class="hero__social">
          <div class="hero__social-item">
            <a :href="others.schoolLink.link" target="_blank" class="contacts__phone contacts__phone--link">
              <i class="el-icon-data-line" style="margin-right: 5px;"></i>
              {{ others.schoolLink.text }}
            </a>
          </div>
          <div class="hero__social-item">
            <a data-sect="#sect4" @click.prevent="scroll" class="contacts__phone contacts__phone--link">
              <i class="el-icon-phone-outline" style="margin-right: 5px;"></i>
              Связаться с нами</a>
          </div>
        </div>
        <div class="hero__content-shell">
          <p class="hero__content-text">Настоящая мужская парикмахерская</p>
          <p class="hero__content-text">без “буржуйских” замашек.</p>
          <p class="hero__content-text">Качественная стрижка за {{ price.haircut }} ₽</p>

        </div>
        <div class="hero__content-btn">
          <div class="button
                     button--size-m


                     button--color-main
                    ">
            <div class="button__text">записаться</div>
            <a class="button__link" target="_blank" :href="others.firstScreenLink"></a>
          </div>
        </div>
      </div>

    </div>
  </section>

</template>

<script>
export default {
  name: "Hero",
  computed: {
    price() {
      return this.$store.state.prices
    },
    others() {
      return this.$store.state.others
    }
  },
  methods: {
    scroll(el) {
      const offset = document.querySelector(`${el.target.dataset.sect}`).getBoundingClientRect().top + pageYOffset
      window.scrollTo({
        top: offset,
        behavior: "smooth"
      });
    }
  }
}
</script>

<style scoped lang="scss">
.hero .hero__social {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

@media (min-width: 1024px) {
  .hero .contacts__phone--link {
    font-size: 14px;
    letter-spacing: 0em;
    line-height: 23px;
  }
}

@media (min-width: 1300px) {
  .hero .contacts__phone--link {
    font-size: 18px;
    letter-spacing: 0em;
    line-height: 23px;
  }
}

@media (max-width: 1023px) {
  .hero__social {
    bottom: 0;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-start;
    padding-top: 100px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

</style>
