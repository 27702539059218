<template>
  <div class="page__top">
    <Header/>
    <div class="page__inner">
      <main class="home">
        <Hero/>
        <Price/>
        <Team/>
        <Game/>
        <Contacts/>
      </main>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Hero from "@/views/home/sections/Hero";
import Price from "@/views/home/sections/Price";
import Team from "@/views/home/sections/Team";
import Game from "@/views/home/sections/Game";
import Contacts from "@/views/home/sections/Contacts";
import {mapActions} from "vuex";

export default {
  name: 'Home',
  components: {
    Hero,
    Price,
    Team,
    Game,
    Contacts,
    Header
  },
  methods: {
    ...mapActions(['loadAppData'])
  },
  created() {
    this.loadAppData()
  }
}
</script>
<style lang="scss">
.hero__img,
.hero-md__img {
  background-attachment: fixed !important;
}

.home {
  .el-tabs__item.is-active {
    color: #e7634d;
  }

  .el-tabs__item {
    color: white;

    &:hover {
      color: #f3978a;

    }
  }

  .team__item {
    min-height: 420px;
    width: calc(25% - 15px);
    box-sizing: border-box;

    .team__description {
      flex: 1;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}

@media (max-width: 1366px) {
  .home .team__item {
    width: calc(33% - 15px);
  }

}
@media (max-width: 1024px) {
  .home .team__item {
    width: calc(50% - 15px);
  }

}
@media (max-width: 768px) {
  .home .team__item {
    margin: 7.5px;
  }
  .home .team {
    flex-wrap: nowrap;
    overflow-x: scroll;
  }

}
</style>
