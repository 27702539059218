<template>
  <section id="sect4" style="background-color: #1B1A1A;">
    <div class="section__title"><span>контакты</span>
    </div>
    <div class="container">
      <el-tabs type="card">
        <el-tab-pane v-for="(item, index) in branch" :label="item.info.name" :key="item.id">
          <div class="contacts">
            <div class="row contacts-row">
              <div class="contacts_column col-sm-4">
                <div class="contacts__item">
                  <div class="contacts__subtitle">Адрес</div>
                  <div class="contacts__description">{{ item.info.address }}
                  </div>
                </div>
                <div class="contacts__item">
                  <div class="contacts__subtitle">Режим работы</div>
                  <div class="contacts__description">
                    <p class="contacts__description">{{ item.info.openingHours }}</p>
                  </div>
                </div>
                <div class="contacts__item" style="margin-bottom: 10px;">
                  <div class="contacts__subtitle">Связь с нами</div>
                  <div class="hero__social hero__social--contact ">
                    <div class="hero__social-item"><a :href="`tel:` + item.info.phone"
                                                      class="contacts__phone">{{ item.info.phone }}</a>
                    </div>
                    <div class="hero__social-item">
                      <a href="https://vk.com/salut_tmn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                          <path
                              d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm.25 16.996h-2.134c-1.205 0-1.409-.687-2.401-1.679-.897-.897-1.395-.209-1.374 1.068.006.339-.161.611-.566.611-1.264 0-3.08.178-4.918-1.806-1.883-2.033-3.857-6.111-3.857-6.513 0-.237.196-.344.524-.344h2.17c.574 0 .623.284.783.649.667 1.521 2.265 4.574 2.69 2.87.244-.978.344-3.245-.703-3.44-.594-.11.452-.746 1.968-.746.377 0 .786.041 1.205.137.769.179.771.523.761 1.026-.039 1.903-.269 3.184.233 3.507.479.31 1.739-1.717 2.403-3.281.183-.433.219-.722.734-.722h2.654c1.39 0-.182 1.997-1.383 3.557-.968 1.255-.916 1.28.209 2.324.803.744 1.75 1.76 1.75 2.336.002.272-.21.446-.748.446z"/>
                        </svg>
                      </a>
                    </div>
                    <div class="hero__social-item" style="color: #fff">
                        @salut.tmn
                    </div>
                  </div>
                </div>
                <div class="button
                                 button--size-m


                                 button--color-transparent
                                ">
                  <div class="button__text">записаться</div>
                  <a class="button__link"
                     :href="item.info.link"></a>
                </div>
                <div class="contacts__item" style="margin-top: 20px;" v-if="item.info.hint.length > 1">
                  <div class="contacts__subtitle">Как добраться</div>
                  <p class="contacts__description" style="display: flex; align-items: center;">
                    <i class="el-icon-location-information" style="margin-right: 6px;"></i>
                    {{ item.info.hint }}
                  </p>
                </div>
              </div>
              <div class="col-sm-8">
                <img class="contacts__img" :src="item.info.objectImg"
                     alt="Офис">
              </div>
            </div>
          </div>

        </el-tab-pane>
      </el-tabs>
    </div>
  </section>
</template>

<script>
export default {
  name: "Contacts",
  computed: {
    branch() {
      return this.$store.state.branch
    },
    // formatPhone(number) {
    //   return number.replace(/[^-0-9]/gim,'')
    // }
  }
}
</script>

<style scoped>

</style>
