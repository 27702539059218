<template>
  <section id="sect2" class="hero-sm hero-md">
    <div class="hero-sm__content">
      <div class="hero-sm__content-shell">
        <div class="hero-sm__content-text">
          <div class="hero__content-shell">
            <p class="hero__content-text">Настоящая мужская парикмахерская</p>
            <p class="hero__content-text">без “буржуйских” замашек.</p>
            <p class="hero__content-text">Качественная стрижка за {{ price.haircut }} ₽</p>
          </div>
        </div>

        <div class="hero-sm__content-btn">
          <div class="button
                     button--size-m


                     button--color-transparent
                    ">
            <div class="button__text">записаться</div>
            <a class="button__link" :href="others.firstScreenLink"></a>
          </div>
        </div>
        <div class="contacts__item" style="margin-top: 15px;">
          <div class="hero__social" style="position: relative; padding: 0;">
            <div class="hero__social-item">
              <a :href="others.schoolLink.link" target="_blank" class="contacts__phone contacts__phone--link">
                <i class="el-icon-data-line" style="margin-right: 5px;"></i>
                {{ others.schoolLink.text }}
              </a>
            </div>
            <div class="hero__social-item">
              <a data-sect="#sect4" @click.prevent="scroll" class="contacts__phone contacts__phone--link">
                <i class="el-icon-phone-outline" style="margin-right: 5px;"></i>
                Связаться с нами</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="hero-md__img"
         style="background: url('static/img/content/hero/hero2.jpg') no-repeat center / cover;">
      <div class="hero-md__content">
        <div class="hero-md__info">
          <div class="hero-md__title">Наши цены</div>
          <div class="hero-md__price">
            <div class="hero-md__price-name">Стрижка</div>
            <div class="hero-md__price-value">{{ price.haircut }} &#8381;</div>
          </div>
          <div class="hero-md__price" v-if="price.haircutUp > 0">
            <div class="hero-md__price-name">Стрижка у старшего мастера</div>
            <div class="hero-md__price-value">{{ price.haircutUp }} &#8381;</div>
          </div>
          <div class="hero-md__price" v-if="price.beard > 0">
            <div class="hero-md__price-name">Борода</div>
            <div class="hero-md__price-value">{{ price.beard }} &#8381;</div>
          </div>
          <div class="hero-md__price" v-if="price.beardUp > 0">
            <div class="hero-md__price-name">Борода у старшего мастера</div>
            <div class="hero-md__price-value">{{ price.beardUp }} &#8381;</div>
          </div>
          <div class="hero-md__price" v-if="price.depilation > 0">
            <div class="hero-md__price-name">Восковая депиляция
              <br>(области носа, ушей, бровей)
            </div>
            <div class="hero-md__price-value">{{ price.depilation }} &#8381;</div>
          </div>
        </div>
      </div>
    </div>
  </section>

</template>

<script>
export default {
  name: "Price",
  computed: {
    price() {
      return this.$store.state.prices
    },
    others() {
      return this.$store.state.others
    }
  },
  mounted() {
    const header = document.getElementsByTagName('header');
    if (header[0]) {
      if (document.body.clientWidth >= 1024) {
        const secondBlock = document.getElementById('sect2');

        const headerHeight = 0;

        window.addEventListener('scroll', function () {
          if (header[0]) {
            const scrollPoint = window.pageYOffset + headerHeight + 280;

            let blockPoint = 0 + (scrollPoint - secondBlock.offsetTop);
            if (blockPoint >= 80) {
              blockPoint = 80;
            }

            if (scrollPoint > secondBlock.offsetTop) {
              header[0].style = `min-height: ${blockPoint}px;`;
            } else {
              header[0].style = `min-height: ${headerHeight}px;`;
            }
          }
        });
      } else {
        const header = document.getElementsByTagName('header');
        header[0].style = `min-height: 80px;`;
      }
    }
  }
}
</script>

<style lang="scss">
#sect2 {
  border-top: 20px solid #1b1a1a;
}
.hero-sm__content .contacts__item .hero__social {
  flex-direction: column;
  align-items: center;
  & > * {
    margin-left: 0px;
    margin-top: 10px;
  }
}
</style>
