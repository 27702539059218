<template>
  <div>
    <div class="header__logo" :class="{toggled: toggle}">
      <svg class="icon__logo" width="1920px" height="1080px">
        <use xlink:href="svg-symbols.svg#logo"></use>
      </svg>
    </div>
    <div class="toggle-menu" :class="{active: toggle}">
      <div class="toggle-menu__inner">
        <div class="toggle-menu__items">
          <div class="toggle-menu__item">
            <div data-sect="#sect1" @click.prevent="scroll" class="toggle-menu__item-title">О нас</div>
          </div>
          <div class="toggle-menu__item">
            <a data-sect="/#sect2" @click.prevent="scroll" class="toggle-menu__item-title">Цены</a>
          </div>
          <div class="toggle-menu__item">
            <a data-sect="#sect3" @click.prevent="scroll" class="toggle-menu__item-title">мастера</a>
          </div>
          <div class="toggle-menu__item">
            <a data-sect="#sect4" @click.prevent="scroll" class="toggle-menu__item-title">контакты</a>
          </div>
        </div>
        <div class="toggle-menu__info">
          <div class="toggle-menu__info-feedback">
            <div class="button button--size-m button--color-main">
              <div class="button__text">записаться</div>
              <a class="button__link" href="https://n427277.yclients.com/group:369697/city:42#1"></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <header class="header" style="z-index: 9;" :class="{toggled: toggle}">
      <div class="header__menu-right" @click="toggle = false">
        <a class="header__menu-link" @click.prevent="scroll" data-sect="#sect1">О нас</a>
        <a class="header__menu-link" @click.prevent="scroll" data-sect="#sect2">Цены</a>
      </div>
      <div class="header__menu-left"  @click="toggle = false">
        <a class="header__menu-link" @click.prevent="scroll" data-sect="#sect3">мастера</a>
        <a class="header__menu-link" @click.prevent="scroll" data-sect="#sect4">контакты</a>
      </div>
      <div class="header__burger">
        <div class="button button--type-burger js-burger" :class="{active: toggle}" @click="toggle = !toggle">
          <div class="button__inner">
            <div class="button__line"></div>
            <div class="button__line"></div>
            <div class="button__line"></div>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      toggle: false
    }
  },
  methods: {
    scroll(el) {
      this.toggle = false
      const offset = document.querySelector(`${el.target.dataset.sect}`).getBoundingClientRect().top + pageYOffset
      window.scrollTo({
        top: offset,
        behavior: "smooth"
      });
    }
  }
}
</script>

<style lang="scss">
.toggle-menu {
  display: block;
  visibility: hidden;
  opacity: 0;
  transition: opacity .3s ease-in-out;

  &.active {
    visibility: visible;
    opacity: 1;

  }
}

.toggle-menu__inner {
  overscroll-behavior: contain;
}
</style>
